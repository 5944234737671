const socialLinks = [
  
  {
    id: 1,
    icon: "github",
    link: "https://github.com/clund6325",
  },
  {
    id: 2,
    icon: "linkedin",
    link: "https://www.linkedin.com/in/cameron-d-lund/",
  },

]

export default socialLinks;