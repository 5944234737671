import postgresqlIcon from '@iconify/icons-logos/postgresql';
import html5 from '@iconify/icons-logos/html-5';
import reactIcon from '@iconify/icons-logos/react';
import languageRubyOnRails from '@iconify/icons-mdi/language-ruby-on-rails';
import javascriptIcon from '@iconify/icons-logos/javascript';
import githubIcon from '@iconify/icons-logos/github-icon';
import sassIcon from '@iconify/icons-logos/sass';
import cSharp from '@iconify/icons-logos/c-sharp';
import wordpressIcon from '@iconify/icons-mdi/wordpress';
import { css } from 'styled-components';





const projectData = [
  {
    id: 1,
    name: 'Lab Notes',
    website: 'https://github.com/clund6325/lab-notes',
    about: 'Lab Notes is a transformative and exciting student organization system created with the tech-savvy student in mind. It revolutionizes student success by implementing agile principles in an easy to to use platform. Although it was created with the bootcamp student in mind, its utility extends to anyone that has the drive to make goals and track them.',
    image: "https://res.cloudinary.com/dz7yojyef/image/upload/v1628189033/portfolio/Screen_Shot_2021-08-05_at_12.43.09_PM_idnh3w.png",
    github: 'https://github.com/clund6325/lab-notes',
    icons: [ reactIcon, languageRubyOnRails, javascriptIcon, postgresqlIcon ]
    
  },
  {
    id: 2,
    name: "What's For Dinner?",
    website: 'https://clund6325.github.io/whatisfordinner/',
    about: "Sick of planning meals every week? What's For Dinner? is a meal planning app that takes the guesswork out of meal planning. It's a simple app that allows you to you to input your favorite recipes, as well as a difficulty rating 1-5 (based on how time consuming the meal is to make), and then generate a meal plan for the week. It's a great way to save time and money by planning your meals in advance. Also included is a 'Work Days' calendar. Select which days you work and the app will not suggest any meals above a 3 in difficulty. Need a break from cooking? Click the fast food button and the app will suggest a fast food restaurant near you. Feel free to add your own restaurants to the list, with the 'Add Restaurant' input section. Stop overthinking meal planning, and start enjoying your life!",
    image: 'https://res.cloudinary.com/dz7yojyef/image/upload/v1716741774/portfolio/logo_syvmrc.png',
    github: 'https://github.com/clund6325/whatisfordinner',
    icons: [ reactIcon, javascriptIcon, cSharp, html5]
  },
];

export default projectData;